body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* width */
::-webkit-scrollbar {
  width: 15px;
  height: 15px;
  border-radius: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: 'transparent';
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #316BFF;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #613BFB;
}

/* The loaded, loading and loading:before are used for overlay and spinner */
.loaded {
  /* */
}

.loading {
  position: relative;
  z-index: 999;
  height: 100%;
  width: 100%;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

/* Transparent Overlay */
.loading:before {
  z-index: 999;
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(256,256,256,0.5) url('shared/images/loading.gif') no-repeat center center;
  background-size: 50px 50px;
  background-color: rgba(128,128,128,0.9);
}

